import React from "react";

const Program2 = () => (
  <div className="block">
    <p>
      As in residential buildings, the demand for heat and electricity in
      non-residential buildings accounts for roughly 30% of scope-1 and scope-2
      emissions. The same challenge thus applies: scaling back heat demand by
      accelerating retrofitting. To scale back electricity use, greater
      efficiency will be needed in the use of electric equipment, such as
      lighting systems and electronic appliances. The program focuses on
      reaching out to and supporting building owners, either individually or
      within the frame of collective pilot projects. Many of the major players,
      like the City of Leuven, KU Leuven, or UZ Leuven, are founding members of
      Leuven 2030, and are committed to taking action and leading by example.
    </p>
  </div>
);

export default Program2;
