import React from "react";

const Explainer = ({ id }) => (
  <React.Fragment>
    <article id={id} className="page is-bluegreen explainer">
      <div
        className="page__inner is-flex"
        data-desktop-observer-target
        data-id={id}
      >
        <div className="page__left is-narrow">
          <h1 className="headline-1">Why this roadmap?</h1>
        </div>
        <div className="page__right is-wide">
          <p>
            In recent years, Leuven 2030 has organized or supported various
            projects and initiatives that contribute to a sustainable future
            with a substantial reduction of emissions. Despite these and other
            efforts, our monitoring shows that carbon emissions in Leuven are
            not falling fast enough. High time to move up a gear! Therefore
            Leuven 2030, alongside a large group of experts from various
            disciplines, has drawn up a Roadmap. This Roadmap 2025 · 2035 · 2050
            shows the major challenges we face and contains a timeline with the
            steps we need to take between now and 2025, 2035 and 2050 to reach
            carbon neutrality. The Roadmap assigns a crucial role to every
            inhabitant, every company, every knowledge institution and every
            government. Their commitment, both individually and in
            collaboration, is crucial to evolve towards a healthy, livable and
            climate-neutral Leuven by 2050.
          </p>
        </div>
      </div>
    </article>
    <article className="page is-blue explainer">
      <div className="page__inner is-flex">
        <div className="page__left is-narrow">
          <h1 className="headline-1">
            HOW DO WE PUT THE ROADMAP INTO PRACTICE?
          </h1>
        </div>
        <div className="page__right is-wide">
          <p>
            In order to put an ambitious plan like the Roadmap into practice,
            nothing can be left to chance and the commitment of many partners is
            essential. That is why, to implement the Roadmap, Leuven 2030 turned
            to its network. For each of the Roadmap’s programs, it appointed one
            or two Program Facilitators. These Program Facilitators, 18 in all,
            are a diverse set of people, including city and university staff, as
            well as people from the private sector. This model of implementation
            reinforces the notion that pursuing climate neutrality is a shared
            project, to which every member of the Leuven community can
            contribute.
          </p>
        </div>
      </div>
    </article>
  </React.Fragment>
);

export default Explainer;
