import React from "react";

const Program10 = () => (
  <div className="block">
    <p>
      Leuven will alleviate land-use pressures by putting a stop to the
      development of vacant spaces unless such development is offset by vacating
      spaces elsewhere (starting from 2025, 15 years ahead of Flanders) and by
      reducing urban sprawl.
    </p>
    <p>
      Leuven will expand green spaces by planting more trees and by creating new
      green spaces in and outside of the city, on public and private land. Paved
      spaces (squares, parking lots, playgrounds,…) will be greened and
      unsealed. Buildings will be outfitted with green roofs and green facades.
      Biodiversity in the built environment will be enhanced. Collectively,
      these measures will help mitigate the heat-island effect and will
      strengthen the city’s resilience to heavy rainfall and droughts.
    </p>
    <p>
      Wherever possible, carbon capture and storage (CCS) will be used,
      sequestering carbon in either biomass or soil. Pilot projects will be set
      up to store carbon captured from the atmosphere into materials.
    </p>
  </div>
);

export default Program10;
