import React from "react";

const Program8 = () => (
  <div className="block">
    <p>
      When it comes to food, there is a strong overlap between sustainability
      and health. Healthy food, with a responsible and balanced ratio between
      animal and vegetal nutrients, has a smaller impact on the climate. Healthy
      food should be strongly promoted, not only through awareness campaigns but
      also by creating an environment that stimulates affordable, healthy, and
      sustainable food in supermarkets, schools, companies, care centers, and so
      on. There should be an emphasis on seasonal food, produced locally and in
      an energye fficient way.
    </p>
    <p>
      The problems of food waste and unsustainable packaging will be tackled by
      developing charters and action plans for the sector’s biggest players.
      Local production will be expanded, both by professional farmers and
      through collective projects. Public participation in food production will
      be encouraged, and the eco-efficiency of production will be sharply
      increased. Alongside KU Leuven and other knowledge institutions, Leuven
      will foster innovation in sustainable agriculture and nutrition.
    </p>
  </div>
);

export default Program8;
