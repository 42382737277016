import React from "react";

const Program3 = () => (
  <div className="block">
    <p>
      All new buildings must be built in accordance with the most stringent
      energy standards in terms of isolation, airtightness, ventilation, and
      heat release. This will ensure that heat demand is kept to the lowest
      possible level. The remaining demand for energy is to be met using
      renewable sources.
    </p>
  </div>
);

export default Program3;
