import React from "react";

const IntroContent = () => (
  <div className="intro-content">
    <div className="intro-content__text">
      <div className="block">
        <h1 className="headline-1 intro-content__title">
          Roadmap
          <br />
          2025 • 2035 • 2050
        </h1>
        <h2 className="intro-content__subtitle headline-3">
          13 programs to a climate-neutral future
        </h2>
      </div>
      <div className="block">
        <p>
          The time is now. Now is the time to shift from ‘doing what can be
          achieved’ to ‘doing what must be achieved’. A shift that implies a
          systemic change to the city and its community. Leuven has the
          ambition, as well as the responsibility, to lead the way. The Roadmap
          2025 · 2035 · 2050, drawn up by Leuven 2030 and numerous experts,
          serves as a guideline for achieving the goal of a climate-neutral city
          by 2050. In September 2019 a professional team of Program Facilitators
          was set up, who will translate this unique plan into further concrete
          action and impact.
        </p>
      </div>
    </div>
  </div>
);

export default IntroContent;
