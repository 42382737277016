import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Program from "./components/program/Program";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Hamburger from "./components/hamburger/Hamburger";

const App = ({ data }) => {
  let allRoutes = [...data.pagesBefore];

  data.themes.forEach((theme) => {
    allRoutes = [...allRoutes, ...theme.items.map((item) => ({ id: item.id }))];
  });

  allRoutes = [...allRoutes, ...data.pagesAfter];

  const routes = allRoutes.map((route) => route.id).join("|");

  useEffect(() => {
    const id = window.location.pathname.replace("/", "");

    if (id) {
      const element = document.querySelector(`#${id}`);
      element.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    window.initStickyPolyfill();
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path={["/", "/intro"]}
          component={(props) => (
            <Homepage routes={allRoutes} data={data} {...props} />
          )}
          exact
        />
        <Route
          path={`/(${routes})/`}
          component={(props) => (
            <Homepage routes={allRoutes} data={data} {...props} />
          )}
          exact
        />
        <Route path="/selectiever-consumeren" exact>
          <Redirect to="/circular-leuven" />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>

      <svg
        className="visually-hidden"
        width="110"
        height="110"
        viewBox="0 0 110 110"
      >
        <defs>
          <mask id="icon-question" x="0" y="0" width="110" height="110">
            <path
              fill="#ffffff"
              d="M55 110C24.6243 110 0 85.3757 0 55S24.6243 0 55 0s55 24.6243 55 55-24.6243 55-55 55zm0-11c24.3005 0 44-19.6995 44-44S79.3005 11 55 11 11 30.6995 11 55s19.6995 44 44 44zm17.875-56.0383c0 3.685-.4635 6.3864-1.3907 8.1044-.927 1.718-2.6685 3.4733-5.2243 5.266-3.8588 2.7388-5.8383 4.7555-5.9385 6.0502l-.0752 1.4192H47.0914c-1.1526-1.5437-1.729-3.3612-1.729-5.4527s.4135-3.7223 1.2404-4.8925 2.3553-2.577 4.5854-4.2202c2.23-1.6433 3.5706-2.7388 4.0217-3.2866.451-.5477.6765-1.3071.6765-2.2782 0-.971-.3508-1.6681-1.0524-2.0914-.7016-.4233-1.8542-.6349-3.4579-.6349-3.107 0-6.2391.2739-9.3963.8216l-1.5786.2241-.5262-11.727C45.5379 28.4212 51.138 27.5 56.6757 27.5c5.5376 0 9.6218 1.1702 12.2528 3.5106 2.631 2.3405 3.9465 6.3241 3.9465 11.9511zM45.2121 82.5V69.055h17.139V82.5H45.212z"
            />
          </mask>

          <mask id="icon-info" x="0" y="0" width="110" height="110">
            <path
              fill="#ffffff"
              d="M55,110 C24.6243388,110 0,85.3756612 0,55 C0,24.6243388 24.6243388,0 55,0 C85.3756612,0 110,24.6243388 110,55 C110,85.3756612 85.3756612,110 55,110 Z M55,99 C79.300529,99 99,79.300529 99,55 C99,30.699471 79.300529,11 55,11 C30.699471,11 11,30.699471 11,55 C11,79.300529 30.699471,99 55,99 Z M46.8230088,81.125 L46.8230088,49.404703 L63.1769912,49.404703 L63.1769912,81.125 L46.8230088,81.125 Z M46.75,42.720297 L46.75,28.875 L63.25,28.875 L63.25,42.720297 L46.75,42.720297 Z"
            ></path>
          </mask>

          <mask id="icon-profile" x="0" y="0" width="110" height="110">
            <path
              fill="#ffffff"
              d="M55 110C24.6243 110 0 85.3757 0 55S24.6243 0 55 0s55 24.6243 55 55-24.6243 55-55 55zm0-10.7027c24.4647 0 44.2973-19.8326 44.2973-44.2973S79.4647 10.7027 55 10.7027 10.7027 30.5353 10.7027 55 30.5353 99.2973 55 99.2973zm0-44.8324c-6.6728 0-12.0822-5.4094-12.0822-12.0822 0-6.6728 5.4094-12.0822 12.0822-12.0822 6.6728 0 12.0822 5.4094 12.0822 12.0822 0 6.6728-5.4094 12.0822-12.0822 12.0822zm19.0151 11.761a23.1537 23.1537 0 0 1 4.3168 13.4855H31.6443c.0264-7.9184 4.053-15.287 10.7027-19.586A75.8108 75.8108 0 0 0 55 61.16a75.8108 75.8108 0 0 0 12.653-1.0346 23.3676 23.3676 0 0 1 6.3621 6.1005z"
            />
          </mask>

          <mask id="icon-home" x="0" y="0" width="110" height="110">
            <path
              fill="#ffffff"
              d="M62.6465 32.9072V28.6h6.6119v10.9108l4.7567 4.7508H35.985L55 25.2703l7.6465 7.6369zM55 110C24.6243 110 0 85.3757 0 55S24.6243 0 55 0s55 24.6243 55 55-24.6243 55-55 55zm0-10.7027c24.4647 0 44.2973-19.8326 44.2973-44.2973S79.4647 10.7027 55 10.7027 10.7027 30.5353 10.7027 55 30.5353 99.2973 55 99.2973zm0-19.4908a70.6022 70.6022 0 0 0-19.027 2.4497V50.8735h38.054v31.3827A70.6022 70.6022 0 0 0 55 79.8065z"
            />
          </mask>
        </defs>
      </svg>

      <div className="hamburger-container">
        <Hamburger />
        <main>
          <React.Fragment>
            {data.pagesBefore.map((page) => {
              const Component = page.component;

              if (!Component) {
                return null;
              }

              return <Component {...page} data={data} key={page.id} />;
            })}
          </React.Fragment>
          <div className="header-container">
            <Header />

            {data.themes.map((theme) =>
              theme.items.map((item) => (
                <Program key={item.id} label={theme.title} {...item} />
              ))
            )}
          </div>

          {data.pagesAfter.map((page) => {
            const Component = page.component;

            if (!Component) {
              return null;
            }

            return <Component {...page} data={data} key={page.id} />;
          })}
        </main>
      </div>

      <Footer />
    </Router>
  );
};

export default App;
