import React from "react";

const Program9 = () => (
  <div className="block">
    <p>
      We live in a world where throwing away materials, our raw materials, is
      the most normal thing in the world. Many materials, which are actually
      still very useful, end up on the ever-increasing mountain of waste. The
      production and processing of these materials accounts for a large
      proportion of Leuven's CO₂ emissions. Instead of dumping and incinerating
      this 'waste', we can actually reuse these materials perfectly. At the
      moment, a city often functions primarily as a linear system. Various flows
      such as biomass (food, wood,...), energy (fossil fuels, ...), minerals
      (metals, building materials, ...), water (drinking water,...) and finished
      goods go into the city. These are converted into infrastructures such as
      buildings or into all kinds of products. Subsequently, outgoing flows such
      as heat surpluses, waste flows, products, ... are generated. In such a
      linear system, a lot of valuable raw materials are lost.
    </p>
    <p>
      However, if you look at a city as a kind of library of materials, you
      suddenly look at a building in a different way. Keeping materials in your
      city, retrieving them from infrastructures as if the city were a 'mine' or
      producing them at a short distance from your city, means that far fewer
      raw materials are lost. In this way we can keep created value in our city
      and we are less dependent on others. So the alternative to a city as a
      linear system is a circular city. A circular city is an important lever to
      reduce Leuven's footprint and to produce less greenhouse gases.
    </p>
    <p>
      Many elements come together in the pursuit of a circular city: producing
      energy as locally as possible, producing food as locally as possible,
      building compact and adaptable buildings, sharing knowledge and data,
      opting for sustainable mobility, conserving water, developing a robust
      network of green elements, stimulating sharing, repairing, reusing and
      recycling, stimulating innovative circular entrepreneurs and employment,
      anchoring circular thinking in the general policy of the local government,
      ...
    </p>
    <p>
      Leuven 2030 and the city of Leuven join forces to transform Leuven into a
      circular city in the next few years, step by step. We are fully committed
      to a circular future that offers enormous opportunities for economic and
      social innovation.
    </p>
  </div>
);

export default Program9;
