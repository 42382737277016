import React from "react";

const Program7 = () => (
  <div className="block">
    <p>
      Leuven will have to generate as much of its own green energy as possible,
      both on its own territory and in surrounding areas, by working with
      neighboring municipalities. The city may also choose to invest in green
      power and heat elsewhere in Belgium.
    </p>
    <p>
      Leuven must go all-in on solar power. By 2030, ten times as much power
      will be generated by photovoltaic panels as today. By 2050, 15 times as
      much. Additionally, an ambitious wind-power plan for the entire Leuven
      region must be developed, with a view towards installing at least 20 wind
      turbines in Leuven and its surrounding areas by 2035. A local energy
      cooperative is to be established, to invest in local production in and
      outside of Leuven. The aim is for at least 40% of households in Leuven to
      have joined up by 2030. A local energy director is to provide support to
      residents and companies in shifting to clean energy, and help coordinate
      the complex energy projects to be carried out in Leuven.
    </p>
    <p>
      Innovative energy concepts are to be adopted and tested in large-scale
      pilot projects at an accelerated pace. These may include: district heating
      using residual, geothermal, or sewage heat; smart micro grids with joint
      production and storage of electricity; and power-to-gas systems as an
      alternative to natural gas.
    </p>
    <p>
      District-level energy strategies will be developed for all of Leuven,
      laying out a clear path for sustainable energy production and
      distribution. No new oil-fired boilers or grid connections for natural gas
      will be installed past 2025. By 2030, 25% of total heat demand (by then
      sharply decreased) will be met with renewable sources. By 2050, 75%.
    </p>
  </div>
);

export default Program7;
