import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <header className="header sticky">
    <Link to="/introduction" className="header__logo">
      <span className="header__logo-bg" />
      <svg width="160" height="76" className="header__logo-icon">
        <title>Back to the introduction</title>
        <use xlinkHref="#logo-white"></use>
      </svg>
    </Link>
  </header>
);

export default Header;
