import React from "react";

const Program1 = () => (
  <div className="block">
    <p>
      Supplying buildings with heat is the single largest source of energy
      consumption; it alone accounts for about 30% of scope-1 and scope-2
      emissions. A major challenge thus lies in speeding up the rate of
      retrofitting, thereby lowering the demand for heat. The remaining demand
      (for heat and electricity) is to be met with renewable energy.
    </p>
    <p>
      The current rate of retrofitting in Leuven is just shy of 1%. The goal is
      to increase this figure to 3%, which corresponds to approximately 1.000
      homes being retrofitted every year. At least 60% of those will need to
      undergo a deep retrofit, scaling back energy use to the lowest possible
      level. The use of fossil fuels for heating, and for the supply of hot
      water for domestic use, will be kept to a minimum.
    </p>
    <p>
      A retrofitted home has many advantages (a lower energy bill, a higher
      level of comfort, higher real-estate value), but getting there is complex.
      There are tens of thousands of home owners in Leuven, and they often lack
      the interest, know-how, or capacity to carry out the measures needed for
      retrofitting. To get them on board, it will be important to provide
      adequate support. The program therefore places a strong focus on actively
      reaching out to home owners and meeting their needs. This will be done
      through offering tailor-made advice, ‘unburdening’ home owners via
      projects of joint retrofitting (in which multiple homes are retrofitted
      simultaneously), and addressing regulatory barriers (either at the local
      level or by reaching out to policymakers at a higher level). For the
      rental market, a separate, sector-specific approach will be developed.
    </p>
  </div>
);

export default Program1;
