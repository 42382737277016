import React, { useState, useRef, useEffect } from "react";

import ProgramHeading from "./ProgramHeading";
import ProgramImage from "./ProgramImage";

import SubProgram from "../subprogram/SubProgram";
import Profile from "../profiles/Profile";
import CTA from "../cta/CTA";

import { getProfilesByProgramID } from "../../helpers";

const Program = ({
  id,
  number,
  label,
  title,
  component,
  parallax,
  copyright,
  subitems,
  extraCta,
}) => {
  const [reloadImage, setReloadImage] = useState(false);
  const [ready, setReady] = useState(false);
  const programRef = useRef(null);

  const profiles = getProfilesByProgramID(id);
  const ProgramComponent = component;

  useEffect(() => {
    const io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setReloadImage(false);

        if (entry.isIntersecting) {
          setTimeout(() => {
            setReady(true);
            setReloadImage(true);
          }, 300);
        }
      });
    });

    io.observe(programRef.current);
  }, [programRef]);

  return (
    <article
      id={id}
      className={`program${ready ? " is-ready" : ""}`}
      ref={programRef}
    >
      <ProgramImage
        id={id}
        ready={reloadImage}
        loadedOnce={ready}
        parallax={parallax}
        copyright={copyright}
      />
      <div className="program-content">
        <div className="block">
          <ProgramHeading number={number} label={label} title={title} />
        </div>

        <ProgramComponent />

        {Boolean(profiles.length) && (
          <div className="block">
            {profiles.length === 1 ? (
              <p>
                <strong>
                  Want to know more about this program? Contact the Program
                  Facilitator.{" "}
                </strong>
              </p>
            ) : (
              <p>
                <strong>
                  Want to know more about this program? Contact one of the
                  Program Facilitators.
                </strong>
              </p>
            )}

            <div className="program-profiles">
              {profiles.map((profile) => (
                <Profile key={profile.id} {...profile} showEmail />
              ))}
            </div>
          </div>
        )}

        {subitems && Boolean(subitems.length) && (
          <div>
            <hr />

            <h2 className="headline-2 is-blue is-center">
              {subitems.length} project clusters
              <span className="subprograms__subtitle">
                We will realise this program with these project clusters
              </span>
            </h2>
            <ul className="subprograms">
              {subitems.map((item) => (
                <li key={item.id}>
                  <SubProgram number={item.id} {...item} />
                </li>
              ))}
            </ul>
          </div>
        )}

        <hr />

        <CTA
          image="./images/roadmap.png"
          text="Want to read the <strong>entire summary of the Roadmap</strong>?"
          meta="PDF • 538KB • 18 pages"
          url="./pdf/Leuven_2030_Roadmap_Summary_version2019.pdf"
          cta="Download"
          isAccent="true"
        />

        {extraCta && (
          <CTA
            {...extraCta}
            isAccent="true"
          />
        )}
      </div>
    </article>
  );
};

export default Program;
