import React from "react";

const Program13 = () => (
  <div className="block">
    <p>
      Acquiring new knowledge, sharing best practices, and monitoring progress
      will be key to implementing the Roadmap and to ensuring a continuous
      learning cycle. This roadmap is a snapshot, based on the current level of
      knowledge and insights. But these are subject to change, and in many areas
      additional research is needed, as well as experimentation through pilot
      projects.
    </p>
    <p>
      The implementation of the Roadmap is itself a learning process: not only
      for Leuven itself, but also for other cities and municipalities, in and
      outside of Flanders, who will follow Leuven’s lead. The impact and
      effectiveness of implementation, but also the way in which it is carried
      out and structured, will be subject to continuous evaluation and
      optimization. Keeping a constant ear to the ground will ensure that
      innovative solutions and new insights, both from within the country and
      abroad, are not overlooked.
    </p>
    <p>
      As a city that has assimilated innovation and learning into its very core,
      Leuven will play a leading part in the sustainable transition. Through its
      own research and innovation – conducted by and achieved through the
      university, the city’s many local experts, Leuven 2030, and Leuven
      MindGate – Leuven will make an active contribution to the growing base of
      knowledge surrounding climate action.
    </p>
    <p>
      Monitoring and reporting emissions and actions will be essential for
      tracking progress. What results have been achieved? Which stakeholders
      have been reached? What barriers have been encountered? Such insights will
      help turn the Roadmap into a living document, that will be systematically
      updated and enriched with the latest insights.
    </p>
  </div>
);

export default Program13;
