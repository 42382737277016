import React from "react";

const Colofon = ({ id }) => (
  <article id={id} className="page is-white colofon">
    <div
      className="page__inner is-flex"
      data-desktop-observer-target
      data-id={id}
    >
      <div className="page__left">
        <h1 className="headline-1">Credits</h1>
        <h2 className="headline-3">Experts</h2>
        <p>
          This Roadmap was made possible by the valued contributions of a large
          number of local experts. The fact of their contribution does not
          necessarily imply that they endorse every part of the Roadmap. The
          experts contributed in their own name and not on behalf of the
          organizations for which they work.
        </p>
        <p>
          Annemie Geeraerd (KU Leuven), Bart Muys (KU Leuven, RvD), Bart
          Vercoutere (VHM, Bringme, RvD, DC), Bert Smits (Tweeperenboom), Bruno
          Motten (KU Leuven), Bruno Verbeeck (Ecolife), Daan Van Tassel (Stad
          Leuven), David Martens (Efika Engineering cvba, RvB), Denise Jacobs
          (Futureproofed), Dirk Vansintjan (Ecopower), Dries Capelle (Stad
          Leuven), Dries Vleugels (Stad Leuven), Erik Béatse (Riso
          Vlaams-Brabant), Evelien Van Bockstal (Vectris), Geert De Pauw (Stad
          Leuven), Geert Vanhorebeek (Stad Leuven), Gert Engelen (Rikolto),
          Hanne Wouters (Leuven 2030 ), Ilse Premereur (Stad Leuven), Inge
          Pauwels (Dialoog vzw), Jan Aerts (Leuven 2030), Johan Van Reeth (BUUR,
          RvD, DC), Jurgen Wauman (ondernemer), Karel Van Acker (KU Leuven),
          Karen Allacker (KU Leuven), Karen De Geyndt (KU Leuven), Katleen Van
          den Broeck (HIVA), Katrien Barrat (C-Change), Katrien Rycken (Leuven
          2030), Kris Bachus (Hiva), Kristine Verachtert (BUUR), Kristof
          Polfliet (Stad Leuven), Kurt Peeters (Stad Leuven), Lien Boogaerts
          (Stad Leuven), Liesbet Veroeveren (Stad Leuven), Luc Wittebolle (Suma
          consulting), Marielle Albert (Stad Leuven), Martin Hermy (KU Leuven),
          Michèle Jacobs (Leuven 2030), Miechel De Paep (BUUR), Miranda Maes
          (Needle), Nico Croes (Boondoggle), Patricia Schoolmeesters (Stad
          Leuven), Pepijn De Snijder (Karditsel bvba), Peter Tom Jones (KU
          Leuven), Peter Willem (Willem-Vereycken bvba), Philippe Cypers (Stad
          Leuven), Pieter Van Steenwegen (KU Leuven), Raf Ghijsen (UZ Leuven,
          RvD), Raf Van Hoorick (KU Leuven), Robin Ibens (Shaved Monkey), Sara
          Timmermans (KU Leuven), Serge de Gheldere (Futureproofed, RvB, DC),
          Stef Steyaert (Levuur cvba), Stefaan Saeys (KU Leuven, voorzitter RvB,
          DC), Steven Van Geertruyden (OCMW Leuven), Stijn De Jonge (KU Leuven,
          RvB), Stijn Neuteleers (UCL), Stijn Van Herck (Stad Leuven), Tessa
          Avermaete (KU Leuven), Tim Asperges (Stad Leuven), Tom Kimps (OCMW
          Leuven), Trui Steen (KU Leuven), Veronique Charlier (Stad Leuven),
          Wiet Vandaele (Stad Leuven, RvB, DC), Wouter Nilis (T’Jonck/Nilis
          ir-arch, RvD).
        </p>
      </div>

      <div className="page__right">
        <div className="block">
          <h2 className="headline-3">Coordination</h2>
          <ul>
            <li>
              Leuven 2030 vzw |{" "}
              <a
                href="https://www.leuven2030.be/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.leuven2030.be
              </a>
            </li>
            <li>
              Katrien Rycken |{" "}
              <a href="mailto:katrien.rycken@leuven2030.be">
                katrien.rycken@leuven2030.be
              </a>
            </li>
            <li>
              Michèle Jacobs |{" "}
              <a href="mailto:michele.jacobs@leuven2030.be">
                michele.jacobs@leuven2030.be
              </a>
            </li>
            <li>
              Jan Aerts |{" "}
              <a href="mailto:jan.aerts@leuven2030.be">
                jan.aerts@leuven2030.be
              </a>
            </li>
          </ul>
        </div>
        <div className="block">
          <h2 className="headline-3">Editing</h2>
          <ul>
            <li>
              BUUR cvba |{" "}
              <a
                href="http://buur.be/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.buur.be
              </a>
            </li>
            <li>
              Miechel De Paep |{" "}
              <a href="mailto:katrien.rycken@leuven2030.be">miechel@buur.be</a>
            </li>
            <li>
              Kristine Verachtert |{" "}
              <a href="mailto:michele.jacobs@leuven2030.be">kristine@buur.be</a>
            </li>
            <li>
              Johan Van Reeth |{" "}
              <a href="mailto:jan.aerts@leuven2030.be">johan@buur.be</a>
            </li>
          </ul>
        </div>

        <div className="block">
          <p>© vzw Leuven 2030 – BUUR cvba</p>
          <a
            className="colofon__logo"
            href="http://buur.be/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./images/buur.png" alt="BUUR cvba" />
          </a>
        </div>

        <div className="block">
          <h2 className="headline-3">Special thanks to</h2>
          <p>
            This Roadmap has been made possible with the financial support of
            AGSL
            <a
              className="colofon__logo"
              href="https://www.agsl.be/nl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./images/AGSL.png" alt="AGSL" />
            </a>
          </p>
          <p>
            Design &amp; development of this Roadmap website
            <a
              className="colofon__logo colofon__logo--wieni"
              href="https://www.wieni.be/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg width="118" height="24">
                <title>Wieni</title>
                <use xlinkHref="#logo-wieni"></use>
              </svg>
            </a>
          </p>
        </div>
      </div>
    </div>
  </article>
);

export default Colofon;
