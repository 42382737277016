import React from "react";

const Program11 = () => (
  <div className="block">
    <p>
      The City of Leuven will consider the transition towards carbon neutrality
      a top priority. Alongside Leuven 2030, it will intensify efforts to
      cooperate with other stakeholders to accelerate the transition. As part of
      this, the city will establish a cross-municipality cooperation platform to
      address, together with its neighboring municipalities, issues that
      transcend the local level.
    </p>
    <p>
      Leuven 2030 will help initiate and scale up the Roadmap’s programs and
      sites. It will enlist program coordinators and appeal to and bring
      together stakeholders. It will also take charge of a number of
      cross-cutting sites itself. These efforts will require a structural
      expansion of Leuven 2030’s capacity.
    </p>
    <p>
      A key aspect of the transition is financing. Achieving the goals defined
      in the Roadmap will require serious investment. Investing in
      sustainability is not only a moral imperative, it is also sensible
      economics, as making investments in the short term will prevent costs from
      rising in the long term. Several tools will be enlisted to help spur
      investment, which may include a local climate fund, ESCOs, energy
      cooperatives, and divestment. Absent the introduction of a binding carbon
      tax, local companies, organizations, and residents will be encouraged to
      make voluntary contributions to offset their carbon footprint. Companies
      will harness the power of innovative entrepreneurship to help drive the
      transition, and will create new economic opportunities in the process.
    </p>
  </div>
);

export default Program11;
