import React from "react";

const Program6 = () => (
  <div className="block">
    <p>
      By 2035 all vehicles in Leuven must be carbon-free. City buses must be
      carbon-free by 2025. To help meet these targets, a network of EV charging
      stations must be set up at an accelerated pace.
    </p>
  </div>
);

export default Program6;
