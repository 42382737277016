import React from "react";

const Program4 = () => (
  <div className="block">
    <p>
      The first building block of a shift to sustainable mobility is reducing
      the need for travel. This can be achieved by increasing density in the
      city and town centers in the Leuven region, enabling more people to live
      in or near the city, thereby lowering the need for travel. Areas and
      services that attract large numbers of people will be situated near
      multimodal nodes. Urban sprawl within the Leuven region will be reduced.
    </p>
  </div>
);

export default Program4;
