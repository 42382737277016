import React from "react";

import { Link } from "react-router-dom";

const Hamburger = () => {
  const showTocClose = () => {
    const tocClose = document.querySelector("[data-toc-close]");

    if (tocClose) {
      tocClose.classList.add("is-visible");
    }
  };

  return (
    <Link
      to="/table-of-contents"
      className="hamburger sticky"
      onClick={showTocClose}
    >
      <svg width="42" height="19" className="hamburger__icon">
        <use xlinkHref="#icon-hamburger"></use>
      </svg>
      <span className="hamburger__label">Menu</span>
    </Link>
  );
};

export default Hamburger;
