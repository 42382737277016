import React from "react";

const Program5 = () => (
  <div className="block">
    <p>
      The second building block is achieving a modal shift. By 2030 the number
      of cyclists and users of public transport needs to have doubled, reducing
      car travel by 20%.
    </p>
    <p>
      Achieving a shift to sustainable modes of transportation is complex. Not
      only will it require a profound change in behavior in a large number of
      Leuven’s residents and visitors, it will also require governments to
      facilitate and steer such behavioral change by taking measures such as:
      introducing low-traffic city centers, neighborhoods, and school
      environments; investing in safe and comfortable cycling infrastructure;
      ensuring a strong and highquality regional public-transport network with
      sufficient capacity and guaranteed passage; tightening parking policy;
      establishing low-emissions zones and road pricing; and facilitating the
      sustainable transport of goods.
    </p>
    <p>
      A key part of the modal shift will be to evolve towards a shared-mobility
      system, in which mobility is offered as a service, and multiple shared
      modes of transportation are clustered, and made more accessible, in
      mobility nodes. Company cars should be discouraged and swiftly phased out.
      Down the line, private car ownership should make way for a system based on
      zero-carbon shared vehicles. Additionally, residents, as well as companies
      and institutions residing in Leuven, will have to significantly scale back
      their air travel.
    </p>
  </div>
);

export default Program5;
