import React from "react";

const Program12 = () => (
  <div className="block">
    <p>
      Implementing the Roadmap will require broad support. Building and
      maintaining that support will require a permanent and citywide effort to
      raise awareness about the importance of taking action. This effort will
      focus not only on the challenges facing Leuven, but also on the
      opportunities those challenges present, for all societal actors:
      governments, companies, knowledge institutions, citizens, and investors.
      Easily accessible information should be made available to anyone wanting
      to take action, but efforts should also target groups that are less
      inclined towards sustainability, to encourage them to act, too. Special
      attention will be devoted to young people, who represent the city’s
      future.
    </p>
    <p>
      Leuven 2030 will continue to expand its base of support. Though it has
      already succeeded at bringing together a wide range of stakeholders, it
      should continue to grow in terms of capacity, resources, network, and
      exposure. It should encourage its members to create or update action
      plans, fold their plans into the ambitions of the Roadmap, and then act on
      them. To succeed, the transition must bring everybody along, including
      societally vulnerable groups. Ensuring social justice across all sites of
      the Roadmap is essential, and must be constantly kept in mind. Wherever
      necessary, supplementary measures should be taken, for example to ensure
      affordable housing or to ensure access to energy and transportation.
    </p>
  </div>
);

export default Program12;
