import React from "react";

const Footer = () => (
  <footer className="footer">
    <a href="https://en.leuven2030.be" className="footer__branding">
      <svg width="160" height="75" className="footer__logo">
        <use xlinkHref="#logo-white"></use>
      </svg>
      <span className="footer__back">
        To the website
        <svg width="7" height="6" className="footer__back-svg">
          <use xlinkHref="#arrow-right"></use>
        </svg>
      </span>
    </a>

    <ul className="footer__social">
      <li>
        <a
          href="https://www.facebook.com/leuven2030"
          className="footer__social-link"
        >
          <svg width="36" height="36" className="footer__social-icon facebook">
            <use xlinkHref="#icon-facebook"></use>
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/leuven2030"
          className="footer__social-link"
        >
          <svg width="36" height="30" className="footer__social-icon twitter">
            <use xlinkHref="#icon-twitter"></use>
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/leuven2030/"
          className="footer__social-link"
        >
          <svg width="33" height="30" className="footer__social-icon linkedin">
            <use xlinkHref="#icon-linkedin"></use>
          </svg>
        </a>
      </li>
    </ul>
  </footer>
);

export default Footer;
