import Intro from "./components/intro/Intro";
import TableOfContents from "./components/toc/TableOfContents";
import Explainer from "./components/explainer/Explainer";
import Colofon from "./components/colofon/Colofon";
import Profiles from "./components/profiles/Profiles";

import Program1 from "./content/programs/Program1";
import Program2 from "./content/programs/Program2";
import Program3 from "./content/programs/Program3";
import Program4 from "./content/programs/Program4";
import Program5 from "./content/programs/Program5";
import Program6 from "./content/programs/Program6";
import Program7 from "./content/programs/Program7";
import Program8 from "./content/programs/Program8";
import Program9 from "./content/programs/Program9";
import Program10 from "./content/programs/Program10";
import Program11 from "./content/programs/Program11";
import Program12 from "./content/programs/Program12";
import Program13 from "./content/programs/Program13";

export default {
  pagesBefore: [
    {
      id: "introduction",
      component: Intro,
      tocTitle: "Introduction",
      tocIcon: "icon-home",
    },
    {
      id: "table-of-contents",
      component: TableOfContents,
    },
    {
      id: "program-facilitators",
      tocTitle: "Program Facilitators",
      tocIcon: "icon-profile",
      title: "To a climate-neutral city with these Program Facilitators",
      component: Profiles,
    },
  ],
  pagesAfter: [
    {
      id: "why-this-roadmap",
      tocTitle: "Why this Roadmap and how do we put it into practice?",
      tocIcon: "icon-question",
      title: "Why this Roadmap and how do we put it into practice?",
      component: Explainer,
    },
    {
      id: "credits",
      tocTitle: "Credits",
      tocIcon: "icon-info",
      title: "Credits",
      component: Colofon,
    },
  ],
  profiles: [
    {
      id: "anne",
      name: "Anne Goidts",
      image: "anne.png",
      email: "anne.goidts@leuven2030.be",
      programs: ["retrofitting-residential-buildings"],
    },
    {
      id: "caroline",
      name: "Caroline Huyghe",
      image: "caroline.png",
      email: "caroline.huyghe@leuven2030.be",
      programs: ["sustainable-and-healthy-eating"],
    },
    {
      id: "dries",
      name: "Dries Vleugels",
      image: "dries.png",
      email: "dries.vleugels@leuven2030.be",
      programs: ["retrofitting-residential-buildings", "generating-green-energy"],
    },
    {
      id: "ewoud",
      name: "Ewoud Monbaliu",
      image: "ewoud.png",
      email: "ewoud.monbaliu@leuven2030.be",
      programs: ["involving-everybody-in-the-transition"],
    },
    {
      id: "ilse",
      name: "Ilse Premereur",
      image: "ilse.jpg",
      email: "ilse.premereur@leuven2030.be",
      programs: ["generating-green-energy"],
    },
    {
      id: "jan",
      name: "Jan Aerts",
      image: "jan.png",
      email: "jan.aerts@leuven2030.be",
      programs: [
        "retrofitting-non-residential-buildings",
        "climate-neutral-new-buildings",
      ],
    },
    {
      id: "jessie",
      name: "Jessie Van Couwenberghe",
      image: "jessie.png",
      email: "jessie.vancouwenberghe@leuven2030.be",
      programs: ["circular-leuven"],
    },
    {
      id: "johan",
      name: "Johan Van Reeth",
      image: "johan.png",
      email: "johan.vanreeth@leuven2030.be",
      programs: ["sustainable-modal-shift", "greening-the-vehicle-fleet"],
    },
    {
      id: "luc",
      name: "Luc Alaerts",
      image: "luc.png",
      email: "luc.alaerts@leuven2030.be",
      programs: ["learning-and-monitoring"],
    },
    {
      id: "michele",
      name: "Michèle Jacobs",
      image: "michele.png",
      email: "michele.jacobs@leuven2030.be",
      programs: ["involving-everybody-in-the-transition"],
    },
    {
      id: "stephan",
      name: "Stephan Reniers",
      image: "stephan.png",
      email: "stephan.reniers@leuven2030.be",
      programs: ["vibrant-centers-and-smart-location-policy"],
    },
    {
      id: "stijn",
      name: "Stijn Adam",
      image: "stijn.png",
      email: "stijn.adam@leuven2030.be",
      programs: ["generating-green-energy"],
    },
    {
      id: "tim",
      name: "Tim Asperges",
      image: "tim.png",
      email: "tim.asperges@leuven2030.be",
      programs: ["sustainable-modal-shift", "greening-the-vehicle-fleet"],
    },
    {
      id: "wim",
      name: "Wim Verheyden",
      image: "wim.png",
      email: "wim.verheyden@leuven2030.be",
      programs: ["green-and-resilient-spaces"],
    },
    {
      id: "katrien",
      name: "Katrien Rycken",
      image: "katrien.png",
      email: "katrien.rycken@leuven2030.be",
      description: "Roadmap manager",
      programs: ["governance-and-financing"]
    },
  ],
  themes: [
    {
      id: "sustainability-in-buildings",
      title: "Sustainability in buildings",
      items: [
        {
          number: 1,
          id: "retrofitting-residential-buildings",
          title: "Retrofitting residential buildings",
          component: Program1,
          subitems: [
            {
              id: "1",
              title: "Reaching out to private owners of residential buildings",
            },
            {
              id: "2",
              title:
                "Providing advisory support for retrofitting residential buildings",
            },
            {
              id: "3",
              title:
                "Addressing regulatory and financial barriers to retrofitting residential buildings",
            },
            {
              id: "4",
              title: "Retrofitting social housing",
            },
            {
              id: "5",
              title: "Setting up projects of joint retrofitting",
            },
            {
              id: "6",
              title:
                "Retrofitting private residential buildings on an individual basis",
            },
            {
              id: "7",
              title:
                "Addressing the energy consumption of equipment and appliances in residential buildings",
            },
          ],
          parallax: [
            {
              image: "P1-layer0.jpg",
              depth: 0,
            },
            {
              image: "P1-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P1-layer2.png",
              depth: 0.15,
            },
            {
              image: "P1-layer3.png",
              depth: 0.2,
            },
          ],
          copyright: "",
        },
        {
          number: 2,
          id: "retrofitting-non-residential-buildings",
          title: "Retrofitting non-residential buildings",
          component: Program2,
          file: {
            size: "651kb",
            pages: "12",
          },
          subitems: [
            {
              id: "8",
              title:
                "Retrofitting the building stock of the major building owners",
            },
            {
              id: "9",
              title: "Retrofitting the remaining, energy-intensive buildings",
            },
            {
              id: "10",
              title:
                "Establishing pilot districts for the non-residential sector",
            },
            {
              id: "11",
              title:
                "Reaching out to the owners of the remaining non-residential buildings",
            },
            {
              id: "12",
              title:
                "Providing advisory support for retrofitting non-residential buildings",
            },
            {
              id: "13",
              title:
                "Addressing regulatory and financial barriers to retrofitting non-residential buildings",
            },
            {
              id: "14",
              title: "Retrofitting individual non-residential buildings",
            },
            {
              id: "15",
              title:
                "Addressing fixed equipment in large non-residential buildings",
            },
            {
              id: "16",
              title:
                "Adapting behavior and the use of equipment in the non-residential sector",
            },
          ],
          parallax: [
            {
              image: "P2-layer0.jpg",
              depth: 0,
            },
            {
              image: "P2-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P2-layer2.png",
              depth: 0.15,
            },
            {
              image: "P2-layer3.png",
              depth: 0.2,
            },
          ],
          copyright: "Geert Vanden Wijngaert",
        },
        {
          number: 3,
          id: "climate-neutral-new-buildings",
          title: "Climate-neutral new buildings",
          component: Program3,
          file: {
            size: "711kb",
            pages: "8",
          },
          subitems: [
            {
              id: "17",
              title: "Addressing the regulation for new buildings",
            },
            {
              id: "18",
              title: "Applying smart design to new “BEN” buildings",
            },
            {
              id: "19",
              title: "Using sustainable and climate-neutral building materials",
            },
          ],
          parallax: [
            {
              image: "P3-layer0.jpg",
              depth: 0,
            },
            {
              image: "P3-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P3-layer2.png",
              depth: 0.15,
            },
            {
              image: "P3-layer3.png",
              depth: 0.06,
            },
          ],
          copyright: "360 Architecten i.o.v. BotaniCo",
        },
      ],
    },
    {
      id: "sustainable-mobility",
      title: "Sustainable mobility",
      items: [
        {
          number: 4,
          id: "vibrant-centers-and-smart-location-policy",
          title: "Vibrant centers and smart location policy",
          component: Program4,
          file: {
            size: "766kb",
            pages: "10",
          },
          subitems: [
            {
              id: "20",
              title: "Making better use of the existing building stock",
            },
            {
              id: "21",
              title:
                "Establishing spatial-planning policy at the regional level",
            },
            {
              id: "22",
              title: "Increasing density and mixing services",
            },
            {
              id: "23",
              title:
                "Clustering popular destinations around public-transportation nodes",
            },
            {
              id: "24",
              title: "Reducing urban sprawl in Oost-Brabant",
            },
            {
              id: "25",
              title: "Rethinking services and commercial activities",
            },
          ],
          parallax: [
            {
              image: "P4-layer0.jpg",
              depth: 0,
            },
            {
              image: "P4-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P4-layer2.png",
              depth: 0.07,
            },
            {
              image: "P4-layer3.png",
              depth: 0.15,
            },
            {
              image: "P4-layer4.png",
              depth: 0.1,
            },
            {
              image: "P4-layer5.png",
              depth: 0.08,
            },
          ],
          copyright: "Milo Profi",
        },
        {
          number: 5,
          id: "sustainable-modal-shift",
          title: "Sustainable modal shift",
          component: Program5,
          file: {
            size: "538kb",
            pages: "16",
          },
          subitems: [
            {
              id: "26",
              title: "Developing an integrated mobility policy",
            },
            {
              id: "27",
              title:
                "Establishing low-traffic city centers, neighborhoods, and school environments",
            },
            {
              id: "28",
              title: "Facilitating travel by foot",
            },
            {
              id: "29",
              title: "Expanding cycling rates",
            },
            {
              id: "30",
              title: "Expanding the use of public transportation",
            },
            {
              id: "31",
              title: "Expanding shared mobility",
            },
            {
              id: "32",
              title: "Discouraging car use and tightening parking policy",
            },
            {
              id: "33",
              title:
                "Facilitating and stimulating the sustainable transport of goods",
            },
            {
              id: "34",
              title: "Reducing air travel",
            },
            {
              id: "35",
              title: "Monitoring travel",
            },
          ],
          parallax: [
            {
              image: "P5-layer0.jpg",
              depth: 0,
            },
            {
              image: "P5-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P5-layer2.png",
              depth: 0.2,
            },
            {
              image: "P5-layer3.png",
              depth: 0.15,
            },
            {
              image: "P5-layer4.png",
              depth: 0.15,
            },
          ],
          copyright: "Toerisme Leuven",
        },
        {
          number: 6,
          id: "greening-the-vehicle-fleet",
          title: "Greening the vehicle fleet",
          component: Program6,
          file: {
            size: "422kb",
            pages: "6",
          },
          subitems: [
            {
              id: "36",
              title: "Setting up a network of EV charging stations",
            },
            {
              id: "37",
              title:
                "Increasing the share of electric vehicles in the private fleet",
            },
            {
              id: "38",
              title: "Increasing the share of electric buses and green trucks",
            },
          ],
          parallax: [
            {
              image: "P6-layer0.jpg",
              depth: 0,
            },
            {
              image: "P6-layer1.jpg",
              depth: 0.077,
            },
            {
              image: "P6-layer2.png",
              depth: 0.085,
            },
            {
              image: "P6-layer3.png",
              depth: 0.1,
            },
          ],
          copyright: "De Lijn",
        },
      ],
    },
    {
      id: "green-energy",
      title: "Green energy",
      items: [
        {
          number: 7,
          id: "generating-green-energy",
          title: "Generating green energy",
          component: Program7,
          file: {
            size: "601kb",
            pages: "14",
          },
          subitems: [
            {
              id: "39",
              title: "Supporting and facilitating the energy transition",
            },
            {
              id: "40",
              title: "Developing district-level energy strategies for Leuven",
            },
            {
              id: "41",
              title: "Producing renewable heat at the building level",
            },
            {
              id: "42",
              title: "Expanding collective heat production",
            },
            {
              id: "43",
              title: "Producing energy with local biomass",
            },
            {
              id: "44",
              title: "Distributing heat",
            },
            {
              id: "45",
              title: "Expanding photovoltaic production",
            },
            {
              id: "46",
              title: "Installing wind turbines",
            },
            {
              id: "47",
              title: "Expanding local energy production and storage",
            },
          ],
          parallax: [
            {
              image: "P7-layer0.jpg",
              depth: 0.08,
            },
            {
              image: "P7-layer1.png",
              depth: 0.15,
            },
          ],
        },
      ],
    },
    {
      id: "sustainable-consumption",
      title: "Sustainable consumption",
      items: [
        {
          number: 8,
          id: "sustainable-and-healthy-eating",
          title: "Sustainable and healthy eating",
          component: Program8,
          file: {
            size: "938kb",
            pages: "10",
          },
          subitems: [
            {
              id: "48",
              title: "Promoting sustainable and healthy food",
            },
            {
              id: "49",
              title: "Decreasing food waste and food packaging",
            },
            {
              id: "50",
              title:
                "Expanding participatory and sustainable food production in the Leuven region",
            },
            {
              id: "51",
              title: "Increasing the eco-efficiency of food production",
            },
            {
              id: "52",
              title:
                "Fostering innovation in sustainable agriculture and nutrition",
            },
          ],
          parallax: [
            {
              image: "P8-layer0.jpg",
              depth: 0,
            },
            {
              image: "P8-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P8-layer2.png",
              depth: 0.09,
            },
            {
              image: "P8-layer3.png",
              depth: 0.1,
            },
            {
              image: "P8-layer4.png",
              depth: 0.13,
            },
          ],
        },
        {
          number: 9,
          id: "circular-leuven",
          title: "Circular Leuven",
          component: Program9,
          file: {
            size: "1455kb",
            pages: "12",
          },
          subitems: [
            {
              id: "53",
              title: "Circular entrepreneurship in Leuven",
            },
            {
              id: "54",
              title: "Building in a circular way",
            },
            {
              id: "55",
              title: "Repair, share and reuse",
            },
            {
              id: "56",
              title: "Sustainable and circular consumption",
            },
            {
              id: "57",
              title:
                "Developing knowledge and embed circular principles in policy",
            },
          ],
          extraCta: {
            image: "./images/circular-leuven.jpg",
            text: "The circular city of Leuven. How would that work?",
            meta: "PDF • 1500KB • 24 pages",
            url: "./pdf/Circular_Leuven.pdf",
            cta: "Download the program",
          },
          parallax: [
            {
              image: "P9-layer0.jpg",
              depth: 0.08,
            },
            {
              image: "P9-layer1.png",
              depth: 0.1,
            },
            {
              image: "P9-layer2.png",
              depth: 0.15,
            },
          ],
          copyright: "Raf Degeest",
        },
      ],
    },
    {
      id: "green-and-resilient-spaces",
      title: "Green and resilient spaces",
      items: [
        {
          number: 10,
          id: "green-and-resilient-spaces",
          title: "Green and resilient spaces",
          component: Program10,
          file: {
            size: "842kb",
            pages: "12",
          },
          subitems: [
            {
              id: "58",
              title: "Alleviating land-use pressures",
            },
            {
              id: "59",
              title: "Expanding green spaces",
            },
            {
              id: "60",
              title: "Enhancing biodiversity in the built environment",
            },
            {
              id: "61",
              title: "Expanding carbon capture and storage",
            },
            {
              id: "62",
              title: "Counteracting the heat-island effect",
            },
            {
              id: "63",
              title: "Increasing resilience to heavy rainfall and droughts",
            },
          ],
          parallax: [
            {
              image: "P10-layer0.jpg",
              depth: 0,
            },
            {
              image: "P10-layer1.jpg",
              depth: 0.08,
            },
            {
              image: "P10-layer2.png",
              depth: 0.1,
            },
            {
              image: "P10-layer3.png",
              depth: 0.12,
            },
            {
              image: "P10-layer4.png",
              depth: 0.15,
            },
          ],
          copyright: "Karl Bruninx",
        },
      ],
    },
    {
      id: "enabling-the-transition",
      title: "Enabling the transition",
      items: [
        {
          number: 11,
          id: "governance-and-financing",
          title: "Governance and financing",
          component: Program11,
          file: {
            size: "642kb",
            pages: "12",
          },
          subitems: [
            {
              id: "64",
              title: "Intensifying stakeholder cooperation",
            },
            {
              id: "65",
              title: "Strengthening urban climate policy",
            },
            {
              id: "66",
              title: "Cooperating across municipalities in the Leuven region",
            },
            {
              id: "67",
              title: "Cooperating with higher authorities",
            },
            {
              id: "68",
              title: "Stimulating sustainable entrepreneurship",
            },
            {
              id: "69",
              title: "Stimulating climate-driven innovative entrepreneurship",
            },
            {
              id: "70",
              title: "Adopting innovative financing mechanisms",
            },
            {
              id: "71",
              title: "Expanding the structural capacity of Leuven 2030",
            },
          ],
          parallax: [
            {
              image: "P11-layer0.jpg",
              depth: 0.08,
            },
            {
              image: "P11-layer1.png",
              depth: 0.1,
            },
            {
              image: "P11-layer2.png",
              depth: 0.15,
            },
          ],
        },
        {
          number: 12,
          id: "involving-everybody-in-the-transition",
          title: "Involving everybody in the transition",
          component: Program12,
          file: {
            size: "628kb",
            pages: "8",
          },
          subitems: [
            {
              id: "72",
              title: "Expanding partnerships and participation",
            },
            {
              id: "73",
              title:
                "Raising awareness among the general public as well as targeted groups",
            },
            {
              id: "74",
              title: "Involving young people",
            },
            {
              id: "75",
              title: "Ensuring social justice",
            },
          ],
          parallax: [
            {
              image: "P12-layer0.jpg",
              depth: 0.07,
            },
            {
              image: "P12-layer1.png",
              depth: 0.11,
            },
            {
              image: "P12-layer2.png",
              depth: 0.15,
            },
          ],
        },
        {
          number: 13,
          id: "learning-and-monitoring",
          title: "Learning and monitoring",
          component: Program13,
          file: {
            size: "586kb",
            pages: "8",
          },
          subitems: [
            {
              id: "76",
              title: "Collecting and monitoring data",
            },
            {
              id: "77",
              title: "Acquiring knowledge and conducting research",
            },
            {
              id: "78",
              title: "Sharing knowledge",
            },
            {
              id: "79",
              title: "Updating the Roadmap",
            },
          ],
          parallax: [
            {
              image: "P13-layer0.jpg",
              depth: 0,
            },
            {
              image: "P13-layer1.jpg",
              depth: 0.06,
            },
            {
              image: "P13-layer2.png",
              depth: 0.1,
            },
            {
              image: "P13-layer3.png",
              depth: 0.12,
            },
            {
              image: "P13-layer4.png",
              depth: 0.15,
            },
          ],
          copyright: "Stijn Bollaert",
        },
      ],
    },
  ],
};
